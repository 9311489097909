<template>
  <div class="container">
    <Breadcrumb />
    <div>
      <el-form label-width="80px">
        <el-form-item label="Class">
          <el-select
            v-model="classIndex"
            placeholder="Select Classes"
            filterable
            class="select-test"
            @change="selectCourse"
          >
            <el-option
              v-for="(test, index) in classes"
              :key="test.id"
              :label="test.student_class_title"
              :value="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Tests">
          <el-select
            v-model="testIndex"
            placeholder="Select Test"
            filterable
            class="select-test"
            @change="selectTest"
          >
            <el-option
              v-for="(test, index) in tests"
              :key="index"
              :label="'Test' + test.which_week + ' (' + test.exam_title +')'"
              :value="index"
              v-show="test.user_exam_id > 0 &&  test.exam_title !== null"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考卷PDF">
          <el-checkbox v-model="getTest">获取考卷PDF</el-checkbox>
        </el-form-item>
        <div class="text-center" style="margin-bottom: 20px">
          <el-button
            type="success"
            @click="startClassGrading"
            :disabled="form.user_exam_id === ''"
          >
            Next
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Tests from "@/apis/tests.js";

export default {
  metaInfo() {
    return {
      title: "My Tests - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      classes: [],
      classIndex: null,
      getTest: true,
      tests: [],
      testIndex: null,
      form: {
        user_exam_id: "",
        type: ""
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.getStudentsExams();
  },

  methods: {
    async getStudentsExams(){
      const res = await Tests.getStudentsExams();
      this.classes = res.student_class_tickets;
    },
    startClassGrading(){
      if(this.getTest){
        this.$router.push({
          path:"/tests/start",
          query: {
            user_exam_id: this.form.user_exam_id
          }
        });
      } else {
        this.$router.push({
          path:"/tests/timer",
          query: this.form
        });
      }
    },
    selectCourse(){
      this.tests = this.classes[this.classIndex].student_class_ticket_exams;
    },
    selectTest() {
      this.form.user_exam_id =  this.tests[this.testIndex].user_exam_id;
      this.form.type = this.tests[this.testIndex].exam_category_title;
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
</style>
